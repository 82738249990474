#footer.hidden {
    display: none;
}

.index-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 86px;

    @media (min-width: 920px) {
        margin-bottom: 200px;

        background-image: url('~/static/ft/img/collage-ellipse.png');
        background-repeat: no-repeat;
        background-position: 90% 40%;
    }

    // @media (min-height: 645px) {
    //     height: 100vh;
    // }

    @media (min-width: 995px) and (min-height: 720px) { // @media (min-width: 920px) and (min-height: 635px)
        padding-top: 0px;
        justify-content: center;

        height: 100vh;
        margin-bottom: 0px;
    }
}

.main-slider {
    flex-shrink: 0;

    width: 100%;
    margin-top: 25px;

    overflow: hidden;

    .swiper-wrapper {
        // minus the height of bullets
        height: calc(100% - 32px);
    }

    .bullets {
        display: flex;
        justify-content: center;
        max-width: var(--page-max-width);
        margin: 0 auto;

        .bullet {
            display: block;
            width: 8px;
            height: 8px;

            padding: 16px;
            margin: 78px 0px;

            border-radius: 50%;
            background-color: $light-grey-three;

            background-clip: content-box;

            &.bullet-active {
                background-color: $text-headline-blue;
            }
        }
    }

    @media (min-width: 980px) {
        .bullets {
            position: relative;
            justify-content: flex-start;
            
            z-index: 10;
            
            .bullet {
                margin: 0px 0px;
            }
        }
    }

    @media (min-width: 995px) and (min-height: 720px) { // @media (min-width: 920px) and (min-height: 635px)
        margin-top: unset;
    }
}

.slides {
    // https://stackoverflow.com/questions/1703183/how-to-not-wrap-contents-of-a-div
    white-space: nowrap;

    // display: flex;
    // column-gap: 120px;

    // overflow: hidden;

    margin: 0px;
    padding: 0px;


    list-style-type: none;
}

.succinct-slider {
    box-sizing: border-box;
    width: 100%;

    padding: 0px 15px;

    background-color: $duke-blue;
    background-image: url('~static/ft/img/noise_cropped--transparent.png');
    background-repeat: repeat;

    .succinct-slides {
        display: flex;
        flex-direction: column;
        flex-grow: 1;

        min-width: 0px;

        margin: 0px;
        padding: 0px;

        list-style-type: none;
    }

    .btn-prev, .btn-next {
        display: none;
    }


    @media (min-width: 460px) {
        padding: 0px 120px;
    }

    @media (max-width: 980px) {
        .succinct-slides {
            // reset Swiper transforms, performed on desktop
            transform: translate3d(0px, 0, 0) !important;
        }
    }

    @media (min-width: 980px) {
        display: flex;
        position: sticky;
        bottom: 0px;
        left: 0;
        width: 100%;
        z-index: 90;

        padding: 0px;

        overflow: hidden;

        .succinct-slides {
            flex-shrink: 0;
            flex-direction: row;

            // to unset width: 100%, set by swiper, which breaks the slide offset
            width: unset;

            // column-gap: 50px;
            // margin: 0px 25px;
            // padding: 0px 25px;
        }

        .slides-container {
            margin: 0px 20px;
            flex-grow: 0;
            width: calc(100vw - 88px - 88px - 40px); // 100vw - button widths - margins
            max-width: calc(100vw - 88px - 88px - 40px);
            // overflow: hidden;
        }

        .btn-prev, .btn-next {
            display: block;

            width: 88px;
            flex-shrink: 0;

            z-index: 10;

            border: none;

            background-color: $text-headline-blue;
            background-repeat: repeat, no-repeat;
            background-position: left, center;
        }

        .btn-prev {
            background-image:
                url('~static/ft/img/noise_cropped--transparent.png'),
                url('~static/ft/img/btn-bwd.svg');

            box-shadow: -10px 0px 20px black;
        }

        .btn-next {
            background-image:
                url('~static/ft/img/noise_cropped--transparent.png'),
                url('~static/ft/img/btn-fwd.svg');

            box-shadow: 10px 0px 20px black;
        }
    }
}
