.show-detail-wrapper {
    @media (min-width: 870px) {
    }
}

.show-detail {
    display: flex;
    flex-direction: column;

    row-gap: 28px;

    font-size: 14px;
    line-height: 21px;

    font-family: $sans;

    .picture-cta {
        display: flex;
        flex-direction: column;
        align-items: center;

        row-gap: 16px;

        color: $purple-navy;

        .picture-container {
            position: relative;
            width: 272px;
            height: 384px;

            .age-tag {
                position: absolute;
                top: -20px;
                right: -20px;

                width: 45px;
                height: 45px;

                font-size: 16px;
                line-height: 45px;
                text-align: center;

                font-weight: 700;

                border-radius: 45px;
                color: $text-headline-midnight;
                background-color: $fill-button-yellow;
            }

            .picture {
                width: 100%;
                height: 100%;
                object-fit: cover;
            }
        }

        .date-pick-container {
            align-self: center;
            position: relative;

            .unwrap-icon {
                position: absolute;
                top: 0px;
                right: 0px;

                width: 20px;
                height: 100%;

                background-image: url('~/static/ft/img/unwrap.svg');
                background-position: center;
                background-repeat: no-repeat;
            }

            .date-pick {
                position: relative;
                width: 100%;
                height: 100%;

                webkit-appearance: none;
                appearance: none;

                font-size: 18px;

                z-index: 5;
                border: none;
                background-color: transparent;
                color: $text-headline-blue;

                font-family: $serif;

                .date {
                    // according to the article below, color and background-color work only in firefox,
                    // though I haven't found them working in firefox (93.0)
                    // https://developer.mozilla.org/en-US/docs/Learn/Forms/Advanced_form_styling#selects_and_datalists)

                    padding: 5px; // doesn't work in any browser, according to the article

                    font-family: inherit; // doesn't work in my firefox

                    color: inherit; // doesn't work in my firefox
                    background-color: transparent; // doesn't work in my firefox
                }

                .date:hover {
                    color: $text-headline-blue;
                }
            }
        }

        .purchase {
            align-self: center;
            display: flex;
            justify-content: center;
            column-gap: 10px;

            padding: 10px 15px;

            font-family: inherit;
            font-size: 16px;
            font-weight: 600;

            border-radius: 30px;
            background-color: $fill-button-yellow;
            text-decoration: none;

            .icon {
                width: 20px;
                height: 20px;

                background-image: url('~/static/ft/img/book.svg');
                background-position: center;
                background-repeat: no-repeat;
            }
        }
    }

    .info {
        color: $text-body-space-cadet;

        .show-title, .additional-info, .premiere-date {
            color: $text-headline-midnight;
        }

        .show-title {
            margin: 0px 0px 20px 0px;
        }

        .main-body {
            margin: 35px 0px 0px 0px;

            & p:nth-child(1)::first-letter {
                font-family: $serif;
                font-weight: 900;

                float: left;

                font-size: 68px;

                margin: 8px 12px 0px 0px;
                line-height: 4rem;
            }

            // p {
            //     margin: 0px;
            // }
        }

        // &:not(.expanded-revealed) .main-body {
        //     margin-bottom: calc(-21px - -7px); // line-height minus half of font-size
        // }

        .additional-info, .premiere-date, .duration {
            margin: 0px;
        }

        .show-title {
            font-size: 34px;
            line-height: 34px;

            font-weight: 700;
            font-family: $serif;
        }

        .share-icon {
            display: none;
        }

        .additional-info.reference {
            font-weight: 600;
        }

        .main-body .capital {
            float: left;

            // margin: 0px 5px;
            margin-right: 7px;

            font-size: 55px;
            line-height: 30px;
            font-weight: 700;

            font-family: $serif;
            color: $text-headline-midnight;
        }

        .expand {
            display: flex;
            align-items: center;

            padding: 0px;

            font-size: inherit;
            line-height: inherit;
            font-weight: 600;
            font-family: inherit;

            border: none;
            color: $text-headline-blue;
            background-color: transparent;

            .unwrap-icon {
                width: 20px;
                height: 20px;

                margin-left: 12px;

                transition: transform 0.5s;

                background-image: url('~/static/ft/img/unwrap.svg');
                background-position: center;
                background-repeat: no-repeat;
            }
        }

        .main-body #expanded {
            display: inline-block;
            overflow: hidden;
            opacity: 1;

            transition: opacity 2s, visibility 2s;
        }

        &:not(.expanded-revealed) .main-body #expanded {
            visibility: hidden;

            height: 0px;

            opacity: 0;
        }

        &.expanded-revealed .expand .unwrap-icon {
            transform: rotate(180deg);
        }
    }


    @media (min-width: 870px) {
        flex-direction: row;
        column-gap: 115px;

        font-size: 16px;
        line-height: 28px;

        .picture-cta {
            align-items: normal;
            row-gap: 24px;

            .picture-container {
                width: 338px;
                height: 472px;

                .age-tag {
                    left: -20px;
                }
            }

            .date-pick-container {
                align-self: normal;

                .date-pick {
                    font-size: 24px;
                }
            }

            .purchase {
                align-self: stretch;
            }
        }

        .info {

            .show-title {
                margin: 0px 0px 32px 0px;
            }

            .main-body {
                margin: 32px 0px 0px 0px;

                & p:nth-child(1)::first-letter {
                    font-size: 94px;

                    margin: 12px 18px 0px 0px;
                }
            }

            // &:not(.expanded-revealed) .main-body {
            //     margin-bottom: calc(-28px - -8px); // line-height minus half of font-size
            // }

            .additional-info, .premiere-date {
                margin: 0px;
            }

            .show-title {
                display: flex;
                align-items: baseline;

                font-size: 48px;
                line-height: 60px;

                .share-icon {
                    display: block;
                    flex-shrink: 0;

                    width: 20px;
                    height: 25px;

                    background-image: url('~/static/ft/img/share.svg');
                    background-position: center;
                    background-repeat: no-repeat;
                }
            }

            .main-body .capital {
                font-size: 94px;
                line-height: 80px;
                margin-right: 20px;
            }
        }
    }
}
