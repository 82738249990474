.news-art {
    padding-top: 120px;
    margin: 0px 0px 80px 0px;

    font-family: $sans;
    font-size: 16px;
    line-height: 28px;

    color: $text-body-black;

    .news-art-title, .news-block-title, .sub-block-title, .art-title {
        font-family: $serif;
    }

    .news-block-title, .sub-block-title, .art-title {
        color: $text-headline-midnight;
    }

    .news-art-title {
        margin: 40px 0px 80px 0px;

        color: $text-headline-blue;
        font-size: 60px;
        line-height: 60px;
    }

    .news-block-title {
        font-size: 40px;
        line-height: 54px;
        margin: 0px 0px 40px 0px;
    }

    .sub-block-title {
        font-size: 32px;
        line-height: 48px;
        margin: 0px 0px 40px 0px;
    }

    .delineator {
        margin: 0px 0px 40px 0px;
    }

    .news-article {
        margin: 0px 0px 40px 0px;

        .art-title {
            font-size: 20px;
            line-height: 28px;
            margin: 0px 0px 16px 0px;
        }

        .date, .description {
            margin: 0px 0px 8px 0px;
        }

        .date {
            color: $text-body-lavender-dark;
        }

        .external {
            display: block;
            color: $text-headline-blue;
        }
    }

    .photo-container {
        margin: 0px 0px 40px 0px;
    }

    .photo-container .photo {
        width: 100%;
    }

    @media (min-width: 465px) {
        .photo-container .photo {
            width: 433px;
            height: 433px;
        }
    }

    @media (min-width: 1100px) {
        display: grid;
        grid-template-columns: auto auto;

        .news-art-title {
            margin: 100px 0px 0px 0px;
        }

        .photo-container {
            grid-column: 2;

            margin: 0px 0px 80px 0px;

            justify-self: end;
        }

        .news-block, .delineator {
            grid-column: 1 / 3;
        }
    }
}
