#actor-brief-wrapper {
    margin-top: 50px;

    background-image: url('~/static/ft/img/collage-ellipse.png'), url('~/static/ft/img/collage-ellipse-blue.png');
    background-repeat: no-repeat, no-repeat;
    background-position: 10% 40%, 75% 40%;

    @media (min-width: 870px) {
        margin-top: 115px;
    }
    @for $i from 1 through 5 {
        &.bg-#{$i} {
            background-image: url('~/static/ft/shapes/backgrounds/#{$i}-optimized.png');
            background-position: 30% 60%;
            background-size: 40rem;
        }
    }
}

#back-btn-container {
    position: relative;

    padding-top: 115px;
    margin-bottom: -50px;

    z-index: 10;

    @media (min-width: 870px) {
        margin-bottom: -135px;
    }
}

#actor-info {
    overflow: hidden;
}

#actor-info {
    #actor-brief-wrapper {
        margin-top: 56px;

        @media(min-width: 870px) {
            margin-top: 115px;
        }
    }

    #actor-brief {
        display: flex;
        flex-direction: column;
        align-items: center;

        text-align: center;

        .photo-container {
            flex-shrink: 0;
            position: relative;

            &::before, &::after {
                background-position: center;
                background-size: contain;
                background-repeat: no-repeat;

                transition: transform 0.5s;
            }

            &::before {
                content: "";
                position: absolute;

                left: 0px;
                // left: -25px;

                width: 170px;
                height: 170px;
                // width: 260px;
                // height: 190px;

                transform: rotateZ(65deg);

                z-index: 1;

                background-image: url('~/static/ft/shapes/quadrangle_blue.png');
            }

            &:hover::before {
                transform: translate(-25px, -10px) rotate(120deg);
            }

            &::after {
                content: "";
                position: absolute;

                width: 120px;
                height: 120px;
                // width: 200px;
                // height: 130px;

                bottom: 0px;
                right: -10px;

                transform: rotateZ(180deg);

                z-index: 10;

                background-image: url('~/static/ft/shapes/Half-circle_red.png');
            }

            &:hover::after {
                transform: translate(50px, 0px) rotate(140deg);
            }

            .photo-wrapper {
                position: relative;

                width: 100%;
                height: 100%;

                z-index: 5;
                border-radius: 50%;

                transition: transform 0.5s;
            }

            &:hover .photo-wrapper {
                transform: scale(1.1);
            }

            .photo {
                width: 100%;
                height: 100%;
                border-radius: 50%;

                object-fit: cover;
            }
        }

        .info {
            .name {
                font-family: $serif;
            }

            .detail {
                font-family: $sans-kyiv-light;
            }

            .detail p:nth-child(2) { // .detail:last-child(p)
                font-size: 14px;
                font-family: $sans;
            }

            .name {
                margin-bottom: 16px;

                font-size: 48px;
                line-height: 60px;
                font-weight: 700;
                color: $text-headline-blue;
            }

            .virtue {
                display: block;
                margin-bottom: 0px;

                font-size: 20px;
                line-height: 32px;
                font-weight: 700;
                color: $text-headline-blue;
            }

            .titles {
                margin: 0px;
            }

            .detail-box {

                max-width: 450px;

                margin: 43px 0px 0px 0px;

                line-height: 28px;
                color: $black;

                p {

                }

                .detail-before, .detail-after {
                    width: unset;
                    position: relative;
                    overflow: visible;
                }

                .detail-before {
                    margin-left: 60px;
                }

                .detail-after {
                    margin-right: 60px;
                }

                .detail-before::before, .detail-after::after {
                    content: "";

                    width: 40px;
                    height: 27px;

                    position: absolute;

                    background-repeat: no-repeat;
                    background-position: center;
                }

                .detail-before::before {
                    top: -14px;
                    left: -60px;

                    background-image: url('~/static/ft/img/quotes-open.svg');

                }

                .detail-after::after {
                    bottom: -14px;
                    right: -60px;

                    background-image: url('~/static/ft/img/quotes-close.svg');
                }

                .detail {
                    margin: 27px 0px;
                    padding: 0;
                }


            }
        }

        @media (min-width: 400px) {
            .photo-container {
                &::before {
                    left: -25px;

                    width: 260px;
                    height: 190px;
                }
                &::after {
                    width: 200px;
                    height: 130px;

                    bottom: 0px;
                    right: -20px;
                }
            }
        }

        @media (min-width: 532px) {
            .photo-container {
                width: 500px;
                height: 500px;

                &::after {
                    right: 0px;
                }
            }
        }

        @media (min-width: 870px) {
            flex-direction: row;
            align-items: flex-start;
            column-gap: 95px;

            // margin-top: 115px;
            text-align: left;

            .photo-container {
                order: 2;
                flex-grow: 0;
            }

            .info {
                order: 1;
                flex-grow: 1;

                .name {
                    margin-top: 65px;
                }

                .detail-box {
                    max-width: unset;
                    width: fit-content;

                    p {
                        width: fit-content;
                    }
                }
            }
        }
    }

    #actor-bio-wrapper {
        .bio-category {

            .category-name {
                display: flex;
                justify-content: space-between;
                align-items: center;

                margin: 40px 0px;

                font-size: 32px;
                font-weight: 700;
                font-family: $serif;
                color: $text-headline-blue;

                list-style: none;
                .name {
                    // overlay the button, so it looks like it's clickable
                    width: 100%;
                    z-index: 10;

                    // prevent text from overlaying the button
                    padding-right: 45px;

                    cursor: pointer;
                }

                .btn {
                    flex-shrink: 0;
                    border: none;
                    width: 45px;
                    height: 45px;

                    // give space to fully wide .name
                    margin-left: -45px;

                    padding: 0px;
                    border-radius: 50%;

                    background-image: url('~/static/ft/img/unwrap.svg');
                    background-position: center;
                    background-repeat: no-repeat;
                    background-color: $fill-button-yellow;

                    transition: transform 0.5s;
                }
            }

            &[open] .category-name .btn {
                transform: rotateZ(180deg);
            }

            .content {
                margin: 0px 0px 40px 0px;
                color: $text-body-space-cadet;

                .date {
                    text-align: right;
                    font-size: 20px;
                    font-family: $serif;
                    font-weight: 700;
                    line-height: 28px;

                    color: $text-headline-midnight;
                }

                @media (min-width: 870px) {
                    grid-template-columns: 1fr 1fr;
                }
            }

            &.right-hand {
                .content .detail-box {
                    grid-column: 2;
                }
            }
        }

        .delineator {
            margin: 0px 15px;
            border: solid 1px $light-grey-three-transparent;
        }
    }

    // @media (min-width: 870px) {
    //     margin-top: 115px;
    // }
}

#shows-involvement {
    overflow: hidden;

    .people-list {
        justify-self: center;

        display: grid;
        flex-wrap: wrap;

        justify-content: center;

        column-gap: 16px;
        grid-template-columns: 1fr;

        .brief-wrapper {
            @media (min-width: 342px) {
                width: 310px;
            }
        }

        @media (min-width: 680px) {
            grid-template-columns: repeat(2, 1fr);
        }

        @media (min-width: 1230px) {
            grid-template-columns: repeat(3, 1fr);
        }

        @media (min-width: 1510px) {
            grid-template-columns: repeat(4, 1fr);
        }
    }
}

#show-video {
    .show-video {
        @include video();
    }
}

#actors {}
