#premieres .news-article {
    .genre {
        font-weight: bold;
        margin: 0px 0px 4px 0px;
    }

    .additional-info {
        margin: 0px 0px 16px 0px;
    }

    .details {
        display: grid;
        grid-template-columns: 33% 1fr;

        margin: 0px 0px 16px 0px;

        .name {
            font-weight: bold;
        }
    }
}
