.shows-container {

    .shows-box {
        .menu {
            .age-selector, .calendar-btn {
                display: none;
            }

            @media (min-width: 1160px) {
                .age-selector {display: flex;}
                .calendar-btn {
                    display: block;
                    padding: 0px 25px 0px 30px;

                    background-image:
                        url('~/static/ft/img/calendar.svg'),
                        url('~/static/ft/img/unwrap.svg');

                    background-position: left center, right center;
                    background-repeat: no-repeat;

                    font-family: inherit;
                    font-size: inherit;
                    color: inherit;
                    background-color: transparent;
                    border: none;
                }
            }
        }

        .show-list {
            margin-bottom: 80px;
        }
    }

    .pagination {
        margin-bottom: 120px;
    }

    @media (min-width: 1160px) {

    }
}
.months {
    font-family: $sans;
    color: $neutral;
    font-size: 1rem;
    display: flex;
    flex-flow: row;
    a {
        color: $text-body-space-cadet;
    }
    ul {
        margin: 0;
        padding: 0;
        list-style: none;
        li {
            display: inline-block;
            margin-left: 1rem;
        }
    }
}
.show-scroll-anchor {
    position: relative;
    top: -8rem;
}
