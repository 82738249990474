.under-construction {
    max-width: var(--page-max-width);
    margin: 0 auto;
    padding-top: 6rem;
    text-align: center;
    h1 {
        font-family: $serif;
        color: $text-headline-blue;
        text-transform: uppercase;
    }
    p {
        font-family: $sans;
    }
    > video {
        max-width: 100%;
        max-height: 65vh;
    }
    margin-bottom: 6rem;
}
