.brief-list-container {
    display: flex;
    overflow: hidden;

    .brief-list-wrapper {
        width: 100%;

        .brief-list {
            display: flex;
            // use Swiper spaceBetween instead
            // column-gap: 88px;

            margin: 0px;
            padding: 0px;

            list-style-type: none;
        }
    }
}
