.show-list {
    display: flex;
    flex-direction: column;

    row-gap: 80px;

    margin: 80px 0px 67px 0px;

    padding: 0px;
}

@media (min-width: 550px) {
    .show-list {
        margin: 80px 0px 120px 0px;
    }
}
