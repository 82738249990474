.slide {
    white-space: normal;
    position: relative;

    // this is the value, set by the tns styles on the element as well
    display: inline-block;
    // display: inline-flex;

    .inner {
        // since the parent isn't flex, inline-flex here won't center horizontally with margin auto
        // so we set this to just flex
        display: flex;
        // display: inline-flex;

        width: 100%;
        max-width: var(--page-max-width);
        margin: 0 auto;

        flex-direction: column;
        align-items: center;
        justify-content: space-between;

        row-gap: 20px;

        @media (min-width: 980px) {
            flex-direction: row;
            .collage-container {
                flex-shrink: 0;
                width: 400px;
                order: 2;
            }

            .info {
                order: 1;
            }
        }
    }

    .info {
        max-width: 650px;
        z-index: 10;
        text-align: center;

        .premiere {
            font-size: 16px;
            line-height: 24px;

            color: $additional-red;
        }
        .date {
            font-size: 24px;
            line-height: 32px;
            margin: 16px 0px;
        }
        .title {
            font-size: 48px;
            line-height: 60px;
            font-weight: 700;
            margin: 16px 0px;
        }
        .detail {
            font-size: 16px;
            line-height: 24px;
            margin: 16px 0px;
        }
        .premiere, .title {
            font-family: $serif;
        }
        .date, .detail {
            font-family: $sans-kyiv-light;
        }

        .date, .title, .detail {
            color: $text-headline-midnight;
        }
        .expand, .purchase {
            margin: 2px 0px;
            padding: 15px 25px;

            border-radius: 30px;

            font-family: $sans-kyiv;
            font-size: 16px;
            line-height: 24px;
            font-weight: 600;

            color: $text-headline-blue;
            border: solid 1px $fill-button-yellow;
            text-decoration: none;
            display: inline-block;

            transition: background-color 0.5s, border-color 0.5s;
            @media (max-width: 400px) {
                font-size: 12px;
            }
        }


        .purchase {
            background-color: $fill-button-yellow;
        }
        .purhcase:hover {
            background-color: $fill-button-hover;
            border-color: $fill-button-hover;
        }
        .purchase:active {
            background-color: $fill-button-active;
            border-color: $fill-button-hover;
        }

        @media (min-width: 980px) {
            text-align: left;

            .premiere {
                margin: 16px 0px;
                padding: 3px 15px;
                border-radius: 5px;

                color: white;
                background-color: $additional-red;
            }
        }

        @media (min-width: 1050px) {
            .expand, .purchase {
                margin: 40px 0px;
            }
        }
    }

    .collage-patch {
        display: none;
    }

    .collage-background {
        display: none;
    }

    .collage-container {
        padding: 2rem;
        .collage {
            position: relative;
            width: 100%;
            height: auto;
        }
    }
}
