.people-list {
    display: grid;
    row-gap: 100px;

    margin: 76px 0px 0px 0px;
    padding: 0px;

    color: $text-headline-purple-navy;

    list-style-type: none;

    .brief-container {
        color: inherit;
        text-decoration: none;
    }
}

@mixin people-two($gap-max) {
    grid-template-columns: auto minmax(0px, $gap-max) auto;
    .brief-wrapper:nth-child(2n+2) {
        grid-column: 3;
    }
}

@mixin people-three($gap-max) {
    grid-template-columns: auto minmax(0px, $gap-max) auto minmax(0px, $gap-max) auto;
    .brief-wrapper:nth-child(3n+1) {
        grid-column: 1;
    }
    .brief-wrapper:nth-child(3n+2) {
        grid-column: 3;
    }
    .brief-wrapper:nth-child(3n+3) {
        grid-column: 5;
    }
}

@mixin people-four($gap-max) {
    grid-template-columns: auto minmax(0px, $gap-max) auto minmax(0px, $gap-max) auto minmax(0px, $gap-max) auto;
    .brief-wrapper:nth-child(4n+1) {
        grid-column: 1;
    }
    .brief-wrapper:nth-child(4n+2) {
        grid-column: 3;
    }
    .brief-wrapper:nth-child(4n+3) {
        grid-column: 5;
    }
    .brief-wrapper:nth-child(4n+4) {
        grid-column: 7;
    }
}
