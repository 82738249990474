#news-container {
    padding-top: 115px;

    .news-list {
        padding: 0px;
        list-style-type: none;

        > .article-brief {
            display: flex;
            flex-direction: column;

            font-family: $sans;
            font-size: 14px;

            color: $text-headline-purple-navy;

            .info {
                .title {
                    margin: 16px 0px 16px 0px;

                    font-family: $serif;
                    font-size: 22px;
                    font-weight: 700;

                    color: $text-headline-midnight;

                    .title-visit-art {
                        color: inherit;
                        text-decoration: none;
                    }
                }

                .detail {
                    margin: 0px 0px 32px 0px;
                    line-height: 22px;
                }
            }

            .visit-art {
                order: 4;
                display: flex;
                width: max-content;

                padding: 15px 25px;
                border-radius: 30px;

                // font-size: 16px;
                font-weight: 600;

                color: $text-headline-blue;

                text-decoration: none;

                .icon {
                    // display: inline-block;
                    width: 20px;
                    height: 20px;

                    margin-left: 6px;

                    background-image: url('~/static/ft/img/arrow-diagonal.svg');
                    background-position: center;

                    background-repeat: no-repeat;
                }
            }

            > .photo {
                margin: 0px 0px 32px 0px;
                width: 100%;
                height: auto;
                object-fit: cover;
            }

            // 246 + 32 = 278
            @media (min-width: 278px) {
                > .photo {
                    width: 246px;
                    height: 246px;
                }
            }

            @media (min-width: 970px) {
                display: grid;
                grid-template-columns: auto auto;
                grid-template-rows: auto auto auto 1fr;

                font-size: 16px;

                > .info {
                    grid-column: 1;
                    max-width: 660px;
                    height: min-content;

                    > .title {
                        font-size: 40px;
                        line-height: 54px;
                    }

                    .detail {
                        line-height: 24px;
                    }
                }

                > .visit-art {
                    grid-row: 3;
                    align-self: flex-start;
                }

                > .photo {
                    grid-column: 2;
                    grid-row: 2 / 5;
                    justify-self: end;
                    margin: 0px;
                }
            }
        }

        .delineator {
            margin: 40px 0px;
            border: solid 1px rgba(166, 166, 166, 0.3);
        }
    }
}
.article-cover {
    display: block;
    margin: $base-gap 0;
    @media (min-width: 50rem) {
        float: left;
        margin-right: $base-gap;
        margin-top: 0;
    }
}
.article-date {
    display: block;
    font-weight: bold;
    color: $purple-navy;
    margin-bottom: $base-gap;
}
