.show-announcement, .show-brief {
    display: flex;
    flex-direction: column;
    align-items: center;

    position: relative;

    font-size: 14px;

    list-style-type: none;

    a {
        text-decoration: none;
        color: inherit;
    }

    .picture-container {
        flex-shrink: 0;

        position: relative;
        width: 166px;
        height: 235px;

        .age-tag {
            position: absolute;
            top: -20px;
            right: -20px;

            width: 46px;
            height: 46px;

            // padding: 10px;

            z-index: 10;

            font-size: 16px;
            line-height: 46px;
            text-align: center;
            font-weight: 600;
            font-family: $sans;

            border-radius: 50%;
            background-color: $fill-button-yellow;
        }

        .picture {
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }

    .links {
        margin: 20px 0px;
        color: $primary-one;

        .expand, .purchase {
            padding: 15px 25px;
            border-radius: 30px;

            color: inherit;
        }

        .purchase {
            display: block;

            font-size: 14px;
            font-family: $sans;
            background-color: $fill-button-yellow;

            text-decoration: none;

            transition: background-color 1s;

            &:hover {
                background-color: $fill-button-hover;
            }
            &:active {
                background-color: $fill-button-active;
            }
        }

        .expand {
            display: none;
        }
    }

    .info > .title {
        font-size: 22px;
        font-weight: 700;
        text-align: center;

        color: $text-headline-midnight;
    }

    .info > .detail, .info > .description {

    }

    @media (min-width: 1150px) {
        flex-direction: row;
        align-items: normal;

        column-gap: 25px;

        font-size: 16px;

        .picture-container {
            .age-tag {
                top: -20px;
                left: -20px;
            }
        }

        .info > .title {
            text-align: left;
        }

        .info > .detail, .info > .description {

        }

        .links {
            display: flex;
            column-gap: 20px;

            position: absolute;
            right: 0px;
            bottom: 0px;

            margin: 0px;

            color: $text-headline-blue;
            font-family: $sans;

            .expand, .purchase {
                display: flex;
                padding: 15px 25px;
                border-radius: 30px;

                font-size: 16px;
                font-weight: 600;

                color: inherit;
                text-decoration: none;

                .icon {
                    // display: inline-block;
                    width: 20px;
                    height: 20px;
                }
            }

            .expand {
                visibility: hidden;
                opacity: 0;

                transition: opacity 1s, visibility 0s, background-color 1s;

                .icon {
                    margin-left: 6px;

                    background-image: url('~/static/ft/img/arrow-diagonal.svg');
                    background-position: center;

                    background-repeat: no-repeat;
                }
            }

            .purchase {
                .icon {
                    margin-right: 6px;

                    background-image: url('~/static/ft/img/book.svg');
                    background-position: center;

                    background-repeat: no-repeat;
                }

                background-color: $fill-button-yellow;
            }
        }

        &:hover .links .expand {
            visibility: visible;
            opacity: 1;
        }
    }
}

.show-announcement {
    font-family: $serif;
    color: $text-headline-purple-navy;

    .picture-container {
        .picture {
            border-radius: 3px;
        }
    }

    .info-container {
        display: flex;
        flex-direction: column;
        align-items: center;

        .daytime-tag {
            display: inline-block;
            margin: 20px 0px;
            margin-bottom: 15px;

            padding: 5px 10px;

            border-radius: 3px;

        }

        .daytime-tag.day {
            color: $text-headline-blue;
            background-color: $fill-button-yellow;
        }

        .daytime-tag.evening {
            color: white;
            background-color: $fill-button-yellow;
        }
    }

    .info {
        display: flex;
        flex-direction: column;
        align-items: center;

        row-gap: 5px;

        > .date, > .month, > .time, > .duration, > .detail {
            display: none;
        }

        > .date, > .month, > .time, > .title {
            color: $text-headline-midnight;
            transition: color 1s;
        }

        > .title {
            order: 2;
        }
        > .price {
            order: 3;
            font-size: 14px;
            font-weight: 600;

            font-family: $sans;
            color: $neutral;
        }

        .date-box {
            order: 1;

            padding: 5px 20px;

            color: $text-headline-midnight;
            border-bottom: solid 1px rgba(120, 112, 140, 0.5);

            .date {
                font-size: 42px;
            }

            .day-time {
                display: inline-block;

                .day, .time {
                    display: block;
                    font-size: 14px;
                    font-family: $sans;
                }
            }

        }
    }

    &:hover .info > .date,
    &:hover .info > .month,
    &:hover .info > .time,
    &:hover .info > .title {
        color: $text-headline-blue;
    }

    @media (min-width: 1150px) {
        .picture-container {
            width: 157px;
            height: 209px;

            .picture {
                transition: transform 0.5s;
            }
        }

        .info-container {
            align-items: flex-start;
        }

        .info-container {
            .daytime-tag {
                margin: 0px;

            }
        }

        .info {
            display: grid;
            align-items: normal;

            grid-column-gap: 32px;

            grid-template-areas:
                "date time title"
                "date duration detail"
                "month . .";

            // font-size: 16px;


            > .date {
                display: block;
                grid-area: date;

                min-width: 120px;

                font-size: 80px;
                font-weight: 700;
            }

            > .month, > .time, > .duration, > .title, > .detail {
                align-self: baseline;
            }

            > .month {
                display: block;
                grid-area: month;
            }

            > .time {
                display: block;
                grid-area: time;

                font-size: 26px;
                font-weight: 700;
            }
            > .duration {
                display: block;
                grid-area: duration;
            }

            > .title {
                grid-area: title;

                font-size: 40px;
                line-height: 54px;


            }
            > .detail {
                display: block;
                grid-area: detail;
                color: $text-headline-purple-navy;
            }

            .date-box, .price {
                display: none;
            }

            > .month, > .duration, > .detail {
                font-family: $sans-kyiv-light !important;
            }
        }

        &:hover .picture-container .picture {
            transform: rotate(-3deg) scale(1.05);
        }
    }
}

.show-brief {
    font-family: $sans;
    color: $text-headline-purple-navy;

    .picture-container {
        margin: 0px 0px 24px 0px;
    }

    .info {
        text-align: center;

        .title {
            font-family: $serif;
        }

        .title, .description {
            margin: 0px 0px 8px 0px;
        }

        .description {
            line-height: 22px;
            font-weight: 400;
        }

        .comment {
            margin: 0px;
            color: $text-headline-blue;
        }
    }

    @media (min-width: 1150px) {
        .picture-container {
            width: 246px;
            height: 344px;

            margin: 0px;
        }

        .info {
            text-align: left;

            .title {
                margin: 0px 0px 24px 0px;

                font-size: 48px;
                line-height: 60px;
            }

            .description {
                margin: 0px 0px 16px 0px;
                line-height: 24px;
            }
        }
    }
}
