.succinct-slide {
    flex-shrink: 0;
    display: flex;
    align-items: center;
    column-gap: 15px;
    flex-shrink: 0;
    max-width: 60rem;

    // to unset the styles, set by swiper
    width: unset;
    height: unset;

    color: white;
    a {
        color: white;
        text-decoration: none;

        .succinct-number {
            color: white;
        }
    }

    margin: 0px 20px;
    border-bottom: solid 1px;

    .succinct-date {
        display: flex;
        column-gap: 3px;

        .succinct-number {
            margin: 28px 0px;

            font-family: $serif;
            font-size: 45px;
            line-height: 50px;
            font-weight: 700;
        }

        .day-time {
            margin: 28px 0px;
            display: flex;
            flex-direction: column;
            justify-content: center;

            .day, .time {
                font-family: $sans;
                font-size: 14px;
                line-height: 18px;
            }

            .day {
                font-weight: 600;
            }
        }
    }

    .name {
        margin: 28px 0px;

        font-family: $sans;
        font-size: 20px;
        font-weight: 600;
    }

    @media (min-width: 980px) {
        margin: 0px;
        border: 0px;

        .name {
            margin: 28px 0px 28px 0px;

            max-width: 150px;

            overflow: hidden;
            text-overflow: ellipsis;

            line-height: 23px;
        }
    }
}
