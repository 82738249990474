.page-container {
    display: grid;
    grid-template-columns: 16px minmax(0px, var(--page-max-width)) 16px;

    row-gap: 100px;

    padding-top: 90px;
    padding-bottom: 120px;

    font-family: $sans;
    font-size: 16px;

    @media(min-width: 1070px) {
        grid-template-columns: minmax(120px, 1fr) minmax(0px, var(--page-max-width)) minmax(120px, 1fr);
    }
    @media(min-width: 1330px) {
        margin: 0px;
        row-gap: 120px;
    }
}
.page-primary-column {
    grid-column: 2;
}
.page-title {
    font-family: $serif;
    font-size: 2.5rem;
}
@mixin page-block($breakpoint, $middle: minmax(0px, var(--page-max-width))) {
    display: grid;
    grid-template-columns: minmax(16px, 1fr) $middle minmax(16px, 1fr);
    @media (min-width: $breakpoint) {
        grid-template-columns: minmax(120px, 1fr) $middle minmax(120px, 1fr);
    }
}
@mixin section-block($breakpoint) {
    margin: 0px auto 56px auto;

    font-family: $sans;
    color: $text-headline-purple-navy;

    font-size: 14px;

    @media (min-width: $breakpoint) {
        margin: 0px 0px 115px 0px;
        font-size: 16px;
    }
}
@mixin main-title($breakpoint) {
    margin: 0px 0px 24px 0px;

    font-family: $serif;
    color: $text-headline-midnight;
    font-size: 22px;
    line-height: 25px;

    @media (min-width: $breakpoint) {
        margin: 0px 0px 56px 0px;

        font-size: 48px;
        line-height: 60px;

        color: $text-headline-blue;
    }
}

.back-btn {
    display: inline-block;
    font-family: $sans;

    padding-left: 35px;

    white-space: nowrap;
    font-weight: 600;
    text-decoration: none;

    background-image: url('~/static/ft/img/arrow-bwd.svg');
    background-position: left center;
    background-repeat: no-repeat;

    &:hover, &:active, &:visited {
        color: $text-headline-blue;
    }

}
.page-primary-column > .back-btn {
    margin-top: $base-gap;
}


.shows-container, .about-container, #news-container,
#shows-tabs, #dates-tabs, #history-tabs,
// contact
.contact-page,
{
    @include page-block(550px)
}

// news
.news-art-container {
    @include page-block(673px)
}

.people-container {
    @include page-block(860px, auto)
}

// show
.show-detail-wrapper, .authors-wrapper, .characters-wrapper,
// person
#actor-info #actor-brief-wrapper, #actor-info #actor-bio-wrapper,
#shows-involvement,
// show and person
.photos-wrapper, .video-wrapper {
    @include section-block(870px);
    @include page-block(1080px);
}

.photos-wrapper:last-child {
    margin-bottom: 8px;
}

// show
.brief-list-wrapper,
// person
#back-btn-container,
#room-tabs {
    @include page-block(1080px);
}

// show
.show-detail, .authors-wrapper .names,
// person
.back-btn-wrapper,
#actor-brief, #actor-bio-wrapper .bio-category, #actor-bio-wrapper .delineator,
#shows-involvement .shows,
// shows, people, about
.shows-box, .shows-container .pagination, .people-container .category, .about-box,
// news-list
.news-list,
// contact
#contact-header,
.contact-item,
.contact-page .delineator,
// news
.news-art,
// show
.video-wrapper .maintain-16-9
{
    grid-column: 2;
}

.section-title {
    grid-column: 2 / 4;
    @include main-title(870px);
}

// https://developer.mozilla.org/en-US/docs/Web/HTML/Element/section#using_a_section_without_a_heading
.title--hidden {
    position: absolute;
    top: -9999px;
    left: -9999px;
}

.brief-list-box, .nav-tabs {
    grid-column: 2 / 4;
}

// show
.characters-wrapper .brief-list-container,
.photos-wrapper .photos-box {
    grid-column: 1 / 4;
}
