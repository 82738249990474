#header {
    display: flex;
    align-items: center;
    justify-content: center;

    position: fixed;
    // position: relative;
    width: calc(100vw - 240px);
    height: 86px;

    padding-left: 120px;
    padding-right: 120px;

    z-index: 100;

    font-family: $sans;

    background-color: transparentize(white, 0.1);
    background-image: url('~/static/ft/img/noise_base.png');
    backdrop-filter: blur(4px);

    .logo {
        position: absolute;
        left: 0px;

        width: 72px;
        height: 35px;
        margin: 0px 32px;

        z-index: 10;

        background-image: url('~/static/ft/img/logo.svg');
        background-size: contain;
        background-position: center;
        background-repeat: no-repeat;
    }
    &.mobile-menu-revealed .logo {
        background-image: url('~/static/ft/img/logo-cream.svg');
    }
    .heading {
        display: none;
    }

    .menu-switch {
        display: none;
    }

    #main-navigation {
        display: flex;
        align-items: center;
        column-gap: 25px;

        width: 100%;
        max-width: var(--page-max-width);
        min-width: 912px;

        position: relative;
        height: 86px;

        .lang-switch {
            display: flex;
            justify-content: center;
            align-items: center;

            column-gap: 5px;
            height: 86px;
            user-select: none;
            font-size: 16px;

            flex-shrink: 0;
            color: $text-body-lavender-dark;
        }
        .lang-switch a {
            color: inherit;
            font-family: inherit;

            text-decoration: none;
        }
        .lang-switch a.active {
            color: $text-headline-blue;
        }
        .search-btn {
            width: 20px;
            height: 20px;

            flex-shrink: 0;
            background-image: url('~/static/ft/img/search-blue.svg');
            background-position: center;
            background-repeat: no-repeat;
        }
        .search-input-container {
            position: relative;
            width: 100%;
            flex-shrink: 1;
            top: unset;
            margin-bottom: unset;
            margin-left: 0px;
            margin-right: 0px;

            z-index: 5;
            transition: opacity 0.2s, visibility 0.2s;

            opacity: 1;
        }
        .search-input-container:not(.revealed) {
            visibility: hidden;
            opacity: 0;
        }
        .search-input {
            padding: 10px 25px;
            padding-right: 35px;

            width: calc(100% - 25px - 35px - 2px);

            background-color: white;
            border: solid 1px rgba(120, 112, 140, 0.2);
            border-radius: 25px;

            &:focus {
                outline: none;
            }
        }
        .search-cross {
            position: absolute;
            top: 0px;
            right: 0px;
            margin: 10px;

            width: 20px;
            height: 20px;

            // z-index: 10;

            background-image: url('~/static/ft/img/cross.svg');
            background-position: center;
            background-repeat: no-repeat;
        }
        .links {
            display: flex;
            align-items: center;
            position: absolute;
            right: 0px;

            column-gap: 64px;
            margin: 0px;
            padding: 0px;

            flex-shrink: 0;

            list-style-type: none;
            font-family: $sans-kyiv;
        }
        // .links-additional {
        //     display: none;
        //     flex-shrink: 0;
        // }
        #nav-social {
            display: none;
        }

        .links a, .links a:visited,
        .links-additional a, .links-additional a:visited {
            color: $text-body-lavender-dark;
            text-decoration: none;
        }

        .links a:hover, .links a:active, .links .active a,
        .links-additional a:hover, .links-additional a:active {
            color: $text-headline-blue;
        }

        .links a {
            font-size: 18px;
            font-weight: 400;
            text-decoration: none;
        }

        @media (max-width: 1190px) {
            flex-direction: column;
            position: absolute;
            top: 0px;
            left: 0px;
            // row-gap: 40px;

            width: 100vw;
            height: 100vh;
            max-width: unset;
            min-width: unset;
            transition: opacity 0.2s, visibility 0.2s;

            background-color: $primary-three;
            background-image: url('~/static/ft/img/noise_cropped--transparent.png');
            background-repeat: repeat;

            .lang-switch {
                width: 100%;
                color: $additional-cream-transparent;
            }
            .lang-switch a.active {
                color: $additional-cream;
            }
            .search-btn {
                align-self: flex-start;
                position: relative;
                top: 10px; // 10 px of search-input padding
                // top: 50px; // the 40 px of row-gap and 10 px of search-input padding

                margin-left: 15px;
                left: 15px;

                z-index: 5;

                background-image: url('~/static/ft/img/search-white.svg');
                background-position: center;
                background-repeat: no-repeat;
            }
            .search-input-container {
                position: relative;
                top: -20px;

                width: calc(100% - 32px);

                margin-bottom: 20px; // 40px - -20px of `top`
                // margin-bottom: -20px;

                margin-left: 16px;
                margin-right: 16px;
            }
            .search-input-container:not(.revealed) {
                visibility: visible;
                opacity: 1;
            }
            .search-input {
                width: calc(100% - 25px - 45px - 2px);
                padding: 10px 25px;
                padding-left: 45px;

                background-color: rgba(0, 4, 178, 0.6);
                border: solid 1px $primary-four;

                &:focus {
                    outline: none;
                }
            }
            .search-cross {
                display: none;
            }
            .links {
                flex-direction: column;
                position: relative;
                row-gap: 25px;

                margin-bottom: 40px;

                li {
                    position: relative;

                    a {
                        position: relative;
                        z-index: 5;
                    }
                }

                & li:nth-child(odd)::after {
                    // z-index: 5;
                    @include shape();
                }

                & li:nth-child(even)::before {
                    // z-index: 5;
                    @include shape();
                }

                #program::after {
                    top: -20px;
                    right: -35px;

                    width: 65px;
                    height: 65px;

                    background-image: url('~/static/ft/shapes/Half-circle_blue.png');
                }
                #repertoire::before {
                    width: 75px;
                    height: 75px;
                    top: -35px;
                    left: -25px;

                    transform: rotate(15deg);

                    background-image: url('~/static/ft/shapes/quadrangle_red.png');
                }
                #team::after {
                    width: 60px;
                    height: 60px;
                    top: -10px;
                    right: -20px;

                    background-image: url('~/static/ft/shapes/nav-shape-2_right--cropped.png');
                }
                #about::before {
                    width: 45px;
                    height: 45px;
                    top: -5px;
                    left: -25px;

                    background-image: url('~/static/ft/shapes/Circle_blue.png');
                }
                #news::after {
                    width: 50px;
                    height: 50px;
                    top: -15px;
                    right: -25px;

                    background-image: url('~/static/ft/shapes/Half-circle_red.png');
                }
                #contact::before {
                    width: 70px;
                    height: 70px;
                    top: -30px;
                    left: -30px;

                    transform: rotate(18deg);

                    background-image: url('~/static/ft/shapes/quadrangle_yellow.png');
                }
            }
            // .links-additional {
            //     display: flex;
            //     flex-direction: column;
            //     align-items: center;
            //     row-gap: 6px;

            //     margin: 0px 0px 40px 0px;
            //     padding: 0px;

            //     list-style-type: none;
            // }
            .links a, .links a:visited, .links a:hover, .links a:active,
            .links-additional a, .links-additional a:visited, .links-additional a:hover, .links-additional a:active {
                color: $additional-cream;
            }
            .links a {
                font-size: 24px;
                font-weight: 700;
            }
            .links-additional a {
                font-size: 14px;
                font-weight: 600;
                text-decoration: none;
            }

            #nav-social {
                display: flex;
                margin-bottom: 43px;

                column-gap: 20px;

                a {
                    display: flex;
                    align-items: center;
                    justify-content: center;

                    width: 48px;
                    height: 48px;

                    border-radius: 50%;
                    background-color: $text-headline-blue;
                }
            }
        }
    }

    @media (max-width: 1190px) {
        border-bottom: solid 1px $light-grey-two-transparent;

        .logo {
            margin: 0px 16px;
        }
        .heading {
            display: block;
            text-align: center;

            font-family: $serif;
            color: $primary-four;
        }
        .menu-switch {
            display: block;
            position: absolute;
            right: 0px;

            width: 20px;
            height: 20px;
            margin: 0px 16px;

            z-index: 10;

            background-color: transparent;
            background-image: url('~/static/ft/img/menu.svg');
            background-position: center;
            background-repeat: no-repeat;
            border: 0px;
        }
        &.mobile-menu-revealed .menu-switch {
            background-image: url('~/static/ft/img/cross.svg');
        }

        &:not(.mobile-menu-revealed) #main-navigation {
            visibility: hidden;
            opacity: 0;
        }

        &:not(.mobile-menu-revealed) .menu-switch .off {
            display: none;
        }
    }
}

.nav-tabs-container {
    padding-top: 120px;
}

.nav-tabs {
    font-size: 24px;
    font-family: $serif;

    overflow-x: auto;

    color: $text-headline-blue;

    &.centered {
        grid-column: 2;
    }

    .tabs {
        display: flex;
        align-items: baseline;
        flex-wrap: nowrap;

        column-gap: 32px;

        overflow-x: auto;

        .category {
            font-size: 24px;
            color: $text-body-lavender-dark;
            text-decoration: none;
        }

        .category.active {
            font-size: 40px;
            color: $text-headline-blue;
        }
        padding-bottom: 0.5rem;
    }

    .patch {
        flex-grow: 1;
    }
}

.pagination {
    .pages {
        display: flex;

        padding: 0px;
        margin: 0;

        font-family: $sans;
        font-weight: 600;
        color: $text-headline-blue;

        .page-num, .arrow-bwd, .arrow-fwd {
            list-style-type: none;

            > .page-num-link {
                display: flex;
                align-items: center;
                justify-content: center;

                width: 24px;
                height: 100%;

                padding: 0px 8px;

                text-decoration: none;
                color: inherit;
                &[aria-current] {
                    width: 40px;
                    height: 40px;

                    margin: 0 8px;
                    padding: 0px;

                    border-radius: 50%;
                    background-color: $fill-button-yellow;
                }
            }
        }

        .arrow-bwd, .arrow-fwd {
            width: 40px;
            height: 40px;

            background-position: center;
            background-repeat: no-repeat;
        }

        .page-num[aria-current], .arrow-bwd, .arrow-fwd {
            a {
                width: 100%;
                padding: 0px;
            }
        }

        .arrow-bwd {
            margin-right: 8px;
            background-image: url('~/static/ft/img/arrow-rtl.svg');
        }

        .arrow-fwd {
            margin-left: 8px;
            background-image: url('~/static/ft/img/arrow-ltr.svg');
        }
    }
}

.button-prev-container, .button-next-container {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-shrink: 0;

    position: relative;
    width: 75px;

    z-index: 10;

    .button {
        background-position: center;
        background-repeat: no-repeat;
    }
}

.button-prev-container {
    margin-right: -75px;

    .button {
        background-image: url('~/static/ft/img/arrow-rtl.svg');
    }
}
.button-next-container {
    margin-left: -75px;

    .button {
        background-image: url('~/static/ft/img/arrow-ltr.svg');
    }
}

.button {
    width: 44px;
    height: 44px;

    margin: auto;

    border-radius: 50%;

    background-color: $fill-button-yellow;
}

@media (min-width: 740px) {
    .button-prev-container, .button-next-container {
        width: 130px;
    }

    .button-prev-container {
        margin-right: -130px;
    }
    .button-next-container {
        margin-left: -130px;
    }
}
