:root {
    --page-max-width: 1050px;
    @media (min-width: 1400px) {
        --page-max-width: 1300px;
    }
    @media (prefers-reduced-motion: no-preference) {
        scroll-behavior: smooth;
    }
}
body {
    margin: 0px;
    padding: 0px;
    position: relative;

    background-color: white;
    background-image: url('~/static/ft/img/noise_base.png');
}

main {
    position: relative;
    margin: auto;
}

@mixin shape() {
    content: "";
    position: absolute;
    width: 85px;
    height: 85px;

    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;
}
.premiere-badge {
    font-family: $serif;
    font-size: 16px;
    line-height: 24px;
    font-weight: 700;
    margin: 16px 0px;
    padding: 3px 15px;
    border-radius: 5px;

    color: white;
    background-color: $additional-red;
}

.drag-scroll-container {
    overflow: hidden;
}

.btn-secondary {
    border: solid 1px $fill-button-yellow;
    &:hover {
        background-color: $fill-button-yellow-semi-transparent;
    }
    &:active {
        background-color: $fill-button-yellow-transparent;
    }
}
.maintain-16-9 {
    position: relative;
    width: 100%;
    height: 0;
    padding-bottom: calc(100% / (16/9));
}
.maintain-16-9 iframe {
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
}
.drag-scroll-container {
    overflow-x: auto;
}
h1, h2, h3 {
    color: $text-headline-blue;
}
.credential {
    display: flex;
    align-items: center;

    line-height: 20px;

    color: inherit;
    text-decoration: none;

    > svg {
        margin-right: 8px;
    }
}

.delineator {
    width: 100%;
    height: 0px;

    margin: 0px;
    color: $light-grey-three-transparent;
    border: 1px solid $light-grey-three-transparent;
}

.to-top-criterion {
    position: absolute;
    top: 150vh;

    z-index: 20;
}

.to-top {
    display: flex;

    position: fixed;
    bottom: 80px;
    right: 50px;

    width: 50px;
    height: 50px;

    border-radius: 50px;
    border: 1px solid $text-headline-blue;

    z-index: 999;

    transition: opacity 0.3s, border-color 0.3s;
    opacity: 1;

    svg {
        margin: auto;
        fill: $text-headline-blue;
        transform: rotate(180deg);

        transition: fill 0.3s;
    }

    @media(min-width: 920px) {
        bottom: 106px;
        right: 120px; // footer padding
    }

    // 1300px (upper --page-max-width) + 120px * 2 = 1540px
    @media(min-width: 1540px) {
        right: 35px; // 120px (footer padding) / 2 - 50px (to-top width) / 2
    }
}

.to-top.white {
    border: 1px solid white;
    svg {
        fill: white;
    }
}

.transparent {
    opacity: 0;
}

.noned {
    display: none !important;
}
