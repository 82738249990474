#footer {

    position: relative;

    padding: 0px 16px;
    font-size: 14px;
    font-family: $sans;

    overflow: hidden;

    color: $cream;
    background-color: $primary-three;
    background-image: url('~/static/ft/img/noise_cropped--transparent.png');
    background-repeat: repeat;

    z-index: 20;

    .content {
        display: grid;
        grid-template-columns: auto min-content;

        max-width: var(--page-max-width);
        margin: auto;
    }

    ul {
        list-style-type: none;
        margin: 0px;
        padding: 0px;
    }

    a {
        color: inherit;
        text-decoration: none;
    }

    .sitemap, .partners, .site-credit, .stamp { // .social, .additional-info
        // max-width: var(--page-max-width);
        // margin: auto;
    }

    padding-top: 44px;

    .sitemap {
        display: none;
    }

    .social {
        grid-column: 1 / 3;
        justify-self: center;

        display: flex;
        align-items: center;

        column-gap: 20px;
        margin-bottom: 46px;

        a {
            display: flex;
            align-items: center;
            justify-content: center;

            width: 48px;
            height: 48px;

            border-radius: 50%;
            background-color: $text-headline-blue;
        }
    }

    .additional-info {
        grid-column: 1;
        display: flex;
        flex-direction: column;
        align-items: center;

        font-style: normal;

        #credentials {
            align-self: flex-start;
            display: flex;
            flex-direction: column;

            row-gap: 12px;
        }

        .credential > svg {
            fill: white;
        }
    }

    .partners {
        grid-column: 1 / 3;
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        justify-content: center;

        column-gap: 16px;
        row-gap: 26px;

        margin: 43px auto;

        .partner {
            height: 32px;
            filter: grayscale(100%);
        }

        #kasa {
            height: 62px;
            margin: -30px 0px -30px 0px;
            filter: grayscale(100%) brightness(100);
        }
    }


    .site-credit {
        display: none;
    }

    .stamp {
        grid-column: 1 / 3;
        font-size: 12px;
        text-align: center;

        margin: 15px auto;
    }

    // .to-top {
    //     grid-row: 2;
    //     grid-column: 2;
    //     align-self: center;
    //
    //     display: flex;
    //
    //     width: 50px;
    //     height: 50px;
    //
    //     border-radius: 50px;
    //     border: 1px solid white;
    //
    //     svg {
    //         margin: auto;
    //         fill: white;
    //         transform: rotate(180deg);
    //     }
    // }

    @media (min-width: 920px) {
        padding: 0px 120px;
        font-size: 16px;

        padding-top: 72px;

        .sitemap {
            grid-column: 1 / 3;

            display: flex;
            justify-content: space-between;
            align-items: flex-start;

            margin-bottom: 46px;

            .sitemap-link {
                font-size: 14px;
                font-weight: 400;
            }

            .branch, #general .sitemap-link {
                font-size: 18px;
                font-weight: 600;
            }

            .branch, .link-container {
                margin: 12px 0px;
            }
        }

        .social {
            grid-column: 2;
            grid-row: 2;

            column-gap: 16px;

            a {
                width: 40px;
                height: 40px;
            }
        }

        .additional-info {
            grid-column: 1;
            grid-row: 2;
            flex-direction: row;
            justify-content: space-between;
            margin-bottom: 46px;

            #credentials {
                align-self: center;
                order: 1;

                flex-direction: row;
                align-items: center;

                column-gap: 30px;
            }
        }

        .partners {
            flex-wrap: nowrap;
            column-gap: 32px;

            margin: auto;
            margin-bottom: 40px;
        }

        .site-credit {
            display: block;
            grid-column: 1 / 3;
            grid-row: 4;

            width: 100%;
            padding: 24px 0px;

            font-size: 14px;
            text-align: center;

            border-top: solid 2px rgba(255, 255, 255, 0.1);
            border-bottom: solid 2px rgba(255, 255, 255, 0.1);
        }

        .stamp {
            font-size: 14px;
            padding: 16px;
        }

        // .to-top {
        //     grid-row: 4;
        //     justify-self: right;
        // }
    }
}
