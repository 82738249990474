.people-container {
    padding: 120px 0px 140px 0px;

    .category {
        width: fit-content;
    }

    .people-list {
        .brief-wrapper {
            width: 310px;

            .photo {
                filter: grayscale(100%);
                // transition: filter $dur-1;
            }

            &:hover .photo, &:focus .photo {
                filter: none;
            }
        }

        // .brief-wrapper width = 310
        // page left-right spacing = 120 || 16
        // 652 = 310 * 2 + 16 * 2 = 620 + 32
        // 1170 = 310 * 3 + 120 * 2

        @media (min-width: 653px) {
            @include people-two(80px)
        }

        @media (min-width: 1170px) {
            @include people-three(80px)
        }

        @media (min-width: 1500px) {
            @include people-four(80px)
        }
    }
}
