.show-container {
    padding-top: 86px;

    .show-detail-wrapper {
        @media (min-width: 870px) {
            margin: 50px 0px 0px 0px;
        }
    }
}


.authors-wrapper {
    padding: 25px 0px 37px 0px;

    .names {
        display: grid;
        grid-template-columns: 1fr 1fr;
        row-gap: 2px;

        padding: 0px;
        margin: 0px;

        color: $text-headline-midnight;

        .role {font-weight: 400; }
        .name {font-weight: 600; color: $text-headline-midnight; text-decoration: none;}
    }

    @media (min-width: 870px) {
        padding: 60px 0px 95px 0px;

        .title {
            margin: 0px 0px 60px 0px;
        }

        .names {
            grid-template-columns: 33% 1fr;
            row-gap: 12px;
        }
    }

    @media (min-width: 870px) {
    }
}

.characters-wrapper {
    .brief-list-container {
        .brief-list-box {
            grid-column: 2;
        }

        .brief-wrapper {
            transition: opacity 0.3s;
        }

        .brief-wrapper.transparent {
            opacity: 1;
        }

        @media (min-width: 600px) {
            .brief-list-box {
                grid-column: 2 / 4;
            }

            .brief-wrapper {
                width: unset;
                padding-right: 120px;
            }
        }

        @media (min-width: 1080px) {
            .brief-wrapper.transparent {
                opacity: 0.2;
            }
        }
    }
}

.video-wrapper {}
