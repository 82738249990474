.about-container {

    background-image: url('~/static/ft/img/collage-ellipse-red.png');
    background-position: top right 80%;
    background-repeat: no-repeat;

    .about-box {
        display: grid;

        @media (min-width: 1200px) {
            grid-template-columns: 1fr 1fr;
            column-gap: 115px;
        }
    }

    #history, #modern, #inclusivity {
        display: flex;
        flex-direction: column;

        @media (min-width: 1200px) {
            grid-column: 1 / 3;

            display: grid;
            grid-template-columns: 1fr 1fr;
            column-gap: 115px;
        }
    }

    .about-box {
        margin: 90px 0px 120px 0px;

        font-family: $sans;
        font-size: 16px;
        line-height: 28px;

        color: $text-body-space-cadet;

        p, h1 {
            margin: 0px;
        }

        #text-first, #text-fifth {
            display: flex;
            flex-direction: column;
            row-gap: 24px;
        }

        #text-second, #text-third, #text-fourth, #section-first {
            font-family: $serif;
        }

        #text-second, #text-fourth {
            font-size: 32px;
            line-height: 48px;
            font-weight: 700;

            color: $black;
        }

        #text-third, #section-first {
            font-size: 20px;
            line-height: 34px;
            font-weight: 400;

            color: $black;
        }

        #photo-hall, #photo-stage, #photo-backyard {
            width: 100%;
        }

        #photo-hall {
            width: 100%;
            height: 100%;

            object-fit: cover;
        }

        #hall-comment {
            color: $text-body-space-cadet-transparent;
            margin-top: 16px;
            margin-bottom: 16px;
        }

        #opening-date {
            display: flex;
            flex-direction: column;
            align-items: center;

            font-family: $serif;
            color: $text-body-space-cadet-transparent-two;

            .month {
                font-size: 52px;
                line-height: 64px;
            }

            .year {
                font-size: 105px;
                line-height: 120px;
                color: $black;

                margin: 8px 0px 0px 0px;
            }

            .comment {
                font-size: 20px;
                line-height: 34px;

                margin: 16px 0px 38px 0px;
            }

            @media (min-width: 1200px) {
                align-items: flex-start;
                justify-content: flex-end;
            }
        }

        #photo-stage {
            margin-top: 48px;
        }

        @media (min-width: 1200px) {
            #hall-comment {
                grid-column: 2;
                margin-bottom: 0px;
            }

            #delineator-first {
                grid-column: 1;
                grid-row: 2;
            }

            #delineator-third, #photo-stage, #delineator-fourth {
                grid-column: 1 / 3;
            }
        }
    }

    #history {
        margin: 32px 0px;

        #text-first {
            margin: 0px 0px 32px 0px;
        }

        #text-second {
            margin: 32px 0px 0px 0px;
        }

        #text-third {
            margin: 32px 0px 0px 0px;
        }

        #text-sixth, #delineator-fifth {
            margin: 32px 0px 0px 0px;
        }

        #text-first {
            order: 1;
        }

        #text-second {
            order: 6;
        }

        #delineator-second {
            order: 2;
        }

        #text-third {
            order: 3;
        }

        #delineator-fifth {
            order: 4;
        }

        #text-sixth {
            order: 5;
        }

        @media (min-width: 1200px) {
            #delineator-second, #delineator-fifth, #text-third, #text-sixth {
                grid-column: 1 / 3;
            }

            #text-second {
                margin: 0px;
            }

            #text-first {
                order: 1;
            }

            #text-second {
                order: 2;
            }

            #delineator-second {
                order: 3;
            }

            #text-third {
                order: 4;
            }

            #delineator-fifth {
                order: 5;
            }

            #text-sixth {
                order: 6;
            }
        }
    }

    #modern {
        margin: 60px 0px;

        #text-fourth {
            margin: 0px 0px 60px 0px;
        }

        @media (min-width: 1200px) {}
    }

    #inclusivity {

        margin: 60px 0px 0px 0px;

        #section-first, #section-second {
            display: block;
        }

        #section-first {
            margin: 20px 0px;
        }

        // #photo-backyard {
        //     order: 1;
        // }
        //
        // #section-first {
        //     order: 2;
        // }
        //
        // #section-second {
        //     order: 3;
        // }

        @media (min-width: 1200px) {
            display: grid;
            grid-template-columns: 1fr 1fr;
            // grid-template-areas: "a b" "a c";

            column-gap: 115px;

            #photo-backyard {
                // grid-area: a;
            }

            .accessibility {
                .accessibility-list {
                    padding: 0px;
                    margin: 24px 0px 24px 15px;
                    // list-style-type:
                }

                #section-first {
                    // grid-area: b;
                    margin: 0px 0px 20px 0px;
                }

                #section-second {
                    // grid-area: c;
                }
            }

            .figures, .motto {
                grid-column: 1 / 3;
                text-align: center;
            }

            .figures {
                margin-top: 60px;
            }

            .motto {
                margin: 24px 0px;
                font-weight: bold;
            }
        }
    }
}
