.photos-wrapper {
    font-family: $sans;

    .photos-box {
        display: flex;
        overflow: hidden;

        .photos-container {
            display: flex;
            width: 100%;

            .show-photo-container {
                width: unset;

                > a {
                    display: block;

                    img {
                        display: block;
                        height: 500px;

                        filter: grayscale(100%);
                        transition: filter $dur-1;
                        &:hover, &:focus {
                            filter: none;
                        }
                    }
                }

            }

        }
    }
}
