.age-selector {
    display: flex;
    align-items: center;
    column-gap: 18px;

    .icon {
        // width: 20px;
        // height: 20px;
        padding-left: 25px;

        background-image: url('~/static/ft/img/baby.svg');
        background-position: left center;
        background-repeat: no-repeat;
    }

    .selector {
        position: relative;
        width: 186px;

        .bullet {
            position: relative;
            width: 36px;
            height: 36px;
            // padding: 9px;

            font-size: 14px;
            line-height: 36px;
            text-align: center;

            border-radius: 50%;

            font-family: $sans;
            background-color: $text-headline-blue;
            color: white;

            z-index: 5;
            user-select: none;
        }

        .line-container {
            display: flex;
            align-items: center;

            position: absolute;
            top: 0px;
            left: 0px;
            width: 100%;
            height: 100%;

            .line-before, .line-after {
                width: 93px;
            }
            .line-before {
                border-bottom: solid 2px $text-headline-blue;
            }
            .line-after {
                border-bottom: solid 2px $light-grey;
            }
        }

    }
}
