.contact-page {
    padding-top: 120px;
    margin: 0px 0px 80px 0px;

    font-family: $serif;
    color: $text-headline-midnight;

    .title, .contact-section-title, .person-title {
        color: inherit;
    }

    .title, .contact-section-title {
        font-size: 32px;
        line-height: 48px;
    }

    .person-title {
        font-size: 16px;
        line-height: 24px;
        font-weight: 800;
    }

    .section-description {
        font-family: $sans-kyiv-light;
    }

    .contact-item {
        margin: 40px 0px;
    }

    #contact-header {
        margin: 0px 0px 60px 0px;
        .osm {
            width: 100%;
        }
    }

    .title {
        margin: 0px 0px 24px 0px;
    }

    .contact-section-title {
        margin: 0px 0px 16px 0px;
    }

    .credential {
        font-family: $sans;
        margin: 0px 0px 11px 0px;
    }

    #cash .section-description {
        margin: 0px 0px 16px 0px;
    }

    #art-director .section-description {
        margin: 0px 0px 8px 0px;
    }

    .credential > svg {
        fill: $text-headline-midnight;
    }

    .info {
        font-style: normal;
    }

    #contact-header {
        display: grid;
        .osm {
            width: 100%;
            grid-row: 2;
            margin: 0px 0px 16px 0px;
            > iframe {
                width: 100%;
                min-height: 18rem;
            }
        }

        @media (min-width: 460px) {
            .photo-container .photo {
                width: unset;
            }
        }

        @media (min-width: 1100px) {
            grid-template-columns: auto auto;
            grid-template-rows: auto 1fr;

            column-gap: 40px;

            > .title {
                max-width: 500px;
            }

            .info {
                grid-column: 1;
            }

            .osm {
                grid-column: 2;
                grid-row: 1 / -1;
                margin: 0px;
            }
        }
    }
}
