@font-face {
    font-family: "KyivType Titling Light";
    src: url("KyivTypeTitling-Light2.woff2") format("woff2"),
         url("KyivTypeTitling-Light2.woff") format("woff");
}


@font-face {
    font-family: "KyivType Titling";
    font-weight: bold;
    src: url("KyivTypeTitling-Bold3.woff2") format("woff2"),
         url("KyivTypeTitling-Bold3.woff") format("woff");
}


@font-face {
    font-family: "KyivType Sans";
    font-weight: medium;
    src: url("KyivTypeSans-Medium2.woff2") format("woff2"),
         url("KyivTypeSans-Medium2.woff") format("woff");
}
