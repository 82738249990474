.nav-tabs-container#room-tabs {
    grid-template-columns: minmax(16px, 1fr) minmax(0px, var(--page-max-width)) minmax(16px, 1fr);

    @media(min-width: 1070px) {
        grid-template-columns: minmax(120px, 1fr) minmax(0px, var(--page-max-width)) minmax(120px, 1fr);
    }
}

.room-container {
    overflow: hidden;

    color: $text-body-space-cadet;

    .heading {
        margin-top: 0px;

        font-family: $serif;

        font-size: 80px;
        font-weight: 700;
        line-height: 64px;

        overflow-wrap: break-word;

        color: $text-body-space-cadet;
    }

    p {
        margin: 0px;
        line-height: 28px;
        margin-bottom: 24px;
    }

    p.accent-text {
        font-family: $serif;
        font-size: 20px;
        line-height: 34px;
    }

    .image-container {
        display: flex;
        flex-direction: column;
        row-gap: 16px;

        .image-comment {
            display: block;
            color: $text-body-space-cadet-transparent;
        }
    }

    .full-width {
        grid-column: 2;
    }

    .accent-section {
        grid-column: 1 / 4;
        background: linear-gradient(to left, rgba(253, 241, 198, 0.4), rgba(255, 188, 0, 0.1));

        display: grid;
        grid-template-columns: 16px minmax(0px, var(--page-max-width)) 16px;

        padding: 120px 0px 120px 0px;

        > .text {
            grid-column: 2;
        }
    }

    .horizontal-toggle {
        grid-column: 1 / 4;

        display: grid;
        grid-template-columns: 16px minmax(0px, var(--page-max-width)) 16px;

        > .text {
            grid-column: 2;
        }

        > .image-container {
            grid-column: 1 / 4;
            margin-bottom: 24px;

            > img.image {
                width: 100%;
            }
        }

        > .image-container:last-child {
            margin-bottom: 0px;
        }
    }

    .two-thirds {
        grid-column: 1 / 4;

        display: grid;
        grid-template-columns: 16px minmax(0px, var(--page-max-width)) 16px;

        row-gap: 100px;

        > .text {
            grid-column: 2;
        }

        > .image-container {
            grid-column: 1 / 4;
        }
    }

    .two-halves {
        grid-column: 1 / 4;

        .content {
            display: grid;
            grid-template-columns: 16px minmax(0px, var(--page-max-width)) 16px;

            > .text {
                grid-column: 2;
            }

            > .image-container {
                margin-bottom: 24px;
                grid-column: 1 / 4;

                > .image {
                    width: 100%;
                }
            }

        }
    }

    .parallel-columns {
        grid-column: 1 / 4;
        display: grid;
        grid-template-columns: 16px minmax(0px, var(--page-max-width)) 16px;

        > .text-column {
            grid-column: 2;
        }

        > .image-column {
            grid-column: 1 / 4;

            > .image-container {
                margin-bottom: 24px;

                > img.image {
                    width: 100%;
                }
            }


            > .image-container:last-child {
                margin-bottom: 0px;
            }
        }
    }

    section {
        position: relative;
    }

    section > * {
        position: relative;
        z-index: 5;
    }

    section:not(.accent-section):nth-child(3n+1)::after {
        content: "";
        position: absolute;
        bottom: -500px;
        left: 0px;

        width: 100%;
        height: 1000px;

        background: radial-gradient(circle 500px at 30% 50%, rgba(255, 92, 0, 0.3), transparent);
    }

    section:not(.accent-section):nth-child(3n+2)::after {
        content: "";
        position: absolute;
        bottom: -700px;
        left: 0px;

        width: 100%;
        height: 1400px;

        background: radial-gradient(circle 700px at 30% 50%, rgba(255, 92, 0, 0.3), transparent);
    }

    section:not(.accent-section):nth-child(3n+3)::after {
        content: "";
        position: absolute;
        bottom: -500px;
        left: 0px;

        width: 100%;
        height: 1000px;

        background: radial-gradient(circle 500px at 70% 50%, rgba(255, 92, 0, 0.3), transparent);
    }

    @media(min-width: 1070px) {
        .accent-section, .horizontal-toggle, .two-thirds, .two-halves .content {
            grid-template-columns: minmax(120px, 1fr) minmax(0px, var(--page-max-width)) minmax(120px, 1fr);
        }

        .text + .image-container, .image-container + .text, .text + .image-container, .image-container + .text, .image-column {
            margin-top: 100px;
        }
    }

    @media(min-width: 1330px) {
        .text + .image-container, .image-container + .text, .cell-text + .cell-image, .cell-image + .cell-text, .image-column {
            margin-top: unset;
        }

        .heading {
            overflow-wrap: normal;
        }

        /*
            Render content over the full width of the main column
        */
        .full-width {
            grid-column: 2;
        }

        .accent-section {
            grid-column: 1 / 4;

            display: grid;
            grid-template-columns: minmax(120px, 1fr) minmax(0px, var(--page-max-width)) minmax(120px, 1fr);

            padding: 160px 0px;

            box-shadow: 0px 0px 20px rgba(255, 188, 0, 0.1);
        }

        /*
            Render a sequence of text and image blocks in rows, two blocks per row
        */
        .horizontal-toggle {
            grid-column: 1 / 4;
            display: grid;
            grid-template-columns: minmax(120px, 1fr) minmax(0px, calc(var(--page-max-width) / 2)) minmax(0px, calc(var(--page-max-width) / 2)) minmax(120px, 1fr);

            margin: 0px;
            row-gap: 100px;

            .image-container {
                margin-bottom: 0px;
            }

            // left-hand columns
            .text:nth-child(2n+1) {
                grid-column: 2;

                > .heading {
                    margin-right: 56px;
                }

                > p {
                    margin-right: 114px;
                }
            }

            .image-container:nth-child(2n+1) {
                grid-column: 1 / 3;

                display: grid;
                grid-template-columns: minmax(120px, 1fr) minmax(0px, calc(var(--page-max-width) / 2));

                > .image {
                    grid-column: 1 / 3;
                }

                > .image-comment {
                    grid-column: 2;
                }
            }

            // right-hand columns
            .text:nth-child(2n+2) {
                grid-column: 3;

                margin-left: 114px;
            }

            .image-container:nth-child(2n+2) {
                grid-column: 3 / 5;
            }

            // if a left-hand cell is the last child, spread it over the full width
            .image-container:nth-child(2n+1):last-child {
                grid-column: 1 / 4;

                grid-template-columns: minmax(120px, 1fr) minmax(0px, var(--page-max-width)) minmax(120px, 1fr);

                > .image {
                    grid-column: 1 / 4;
                    max-width: 1000px;
                }
            }

            .text:nth-child(2n+1):last-child {
                grid-column: 2 / 4;
            }
        }

        /*
            A sequence of blocks
        */
        .two-thirds {
            grid-column: 1 / 4;
            display: grid;
            grid-template-columns: minmax(120px, 1fr) minmax(0px, calc(var(--page-max-width) / 3)) minmax(0px, calc(var(--page-max-width) / 3)) minmax(0px, calc(var(--page-max-width) / 3)) minmax(120px, 1fr);
            row-gap: 100px;

            >:nth-child(2n+1) {
                grid-column: 2 / 4;
            }

            >:nth-child(2n+2) {
                grid-column: 3 / 5;
            }
        }

        .two-halves {
            grid-column: 1 / 4;

            display: grid;
            grid-template-columns: minmax(120px, 1fr) minmax(0px, var(--page-max-width)) minmax(120px, 1fr);

            .content {
                grid-column: 2;

                display: block;
                column-count: 2;
                column-gap: 114px;

                > .image-container, > * {
                    margin-bottom: 100px;
                }

                > .text {
                    .heading {
                        max-width: 636px;
                        overflow-wrap: break-word;
                    }

                    p {
                        max-width: 536px;
                    }
                }
            }
        }

        /*
            Separate containers for a text block and image blocks
        */
        .parallel-columns {
            grid-column: 2;

            grid-template-columns: 1fr 1fr;

            .text-column {
                grid-column: 1;

                margin-right: 114px;
            }

            .image-column {
                grid-column: 2;

                display: flex;
                flex-direction: column;
                row-gap: 100px;

                > .image-container {
                    margin-bottom: 0px;
                }
            }
        }

        section:not(.accent-section):nth-child(3n+1)::after {
            bottom: -700px;
            height: 1400px;

            background: radial-gradient(circle 700px at 30% 50%, rgba(255, 92, 0, 0.3), transparent);
        }

        section:not(.accent-section):nth-child(3n+2)::after {
            bottom: -900px;
            height: 1800px;

            background: radial-gradient(circle 900px at 30% 50%, rgba(255, 92, 0, 0.3), transparent);
        }

        section:not(.accent-section):nth-child(3n+3)::after {
            bottom: -700px;
            height: 1400px;

            background: radial-gradient(circle 700px at 70% 50%, rgba(255, 92, 0, 0.3), transparent);
        }
    }

    @media (min-width: 1660px) { // 1300 (upper --page-max-width) + 180 * 2
        max-width: 1660px;
        margin: auto;

        overflow: visible;

        section:not(.accent-section):nth-child(3n+1)::after,
        section:not(.accent-section):nth-child(3n+2)::after,
        section:not(.accent-section):nth-child(3n+3)::after {
            width: 100vw;
            left: calc((100% - 100vw) / 2);
        }

        .horizontal-toggle {
            grid-template-columns:
                180px
                minmax(0px, calc(var(--page-max-width) / 2)) minmax(0px, calc(var(--page-max-width) / 2))
                180px;
        }
    }
}
