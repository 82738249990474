.brief-wrapper {
    .brief-container {
        color: $text-headline-purple-navy;
        text-decoration: none;
        display: flex;
        flex-direction: column;
        align-items: center;

        .photo-container {
            position: relative;

            width: 270px;
            height: 270px;

            .photo-wrapper {
                width: 100%;
                height: 100%;

                overflow: hidden;

                border-radius: 50%;

                .photo {
                    position: relative;

                    width: 100%;
                    height: 100%;

                    z-index: 5;

                    transition: transform 1s, filter 1s;
                    object-fit: cover;
                }
            }
        }

        .info {
            margin-top: 16px;

            font-size: 14px;
            font-family: $serif;
            text-align: center;

            .title, .detail, .titles {
                margin: 4px 0px;
            }

            .title {
                color: $text-headline-midnight;
                transition: color 1s;
            }

            .titles, .detail {
                font-family: $sans-kyiv-light;
            }
        }

        &:hover .info .title {
            color: $text-headline-blue;
        }

        .photo-container::before, .photo-container::after {
            transition: transform 1s, top 1s, right 1s, bottom 1s, left 1s;
        }

        @media (min-width: 870px) {
            .photo-container {
            }

            .info {
                margin-top: 35px;
                font-size: 16px;
            }
        }
    }

    &:nth-child(3n+1) .photo-container::before {
        top: 25px;
        left: -25px;

        @include shape();

        width: 125px;
        height: 125px;

        transform: rotate(110deg);
        background-image: url('~/static/ft/shapes/quadrangle_blue.png');
    }
    &:nth-child(3n+1) .photo-container::after {
        top: 140px;
        right: -20px;
        // left: 200px;

        z-index: 10;
        @include shape();
        background-image: url('~/static/ft/shapes/Half-circle_red.png');
    }

    &:nth-child(3n+2) .photo-container::before {
        left: -25px;

        @include shape();

        width: 100px;
        height: 100px;

        background-image: url('~/static/ft/shapes/person-shape-left2--cropped01.png');
    }
    &:nth-child(3n+2) .photo-container::after {
        top: 120px;
        right: -20px;

        z-index: 10;
        @include shape();

        width: 60px;
        height: 60px;

        background-image: url('~/static/ft/shapes/Circle_blue.png');
    }

    &:nth-child(3n+3) .photo-container::before {
        bottom: 10px;
        left: -15px;

        @include shape();

        width: 130px;
        height: 130px;

        transform: rotate(10deg);
        background-image: url('~/static/ft/shapes/quadrangle_red.png');
    }
    &:nth-child(3n+3) .photo-container::after {
        top: 50px;
        right: -40px;

        z-index: 10;

        @include shape();

        width: 100px;
        height: 100px;

        background-image: url('~/static/ft/shapes/Half-circle_yellow.png');
    }

    &:hover {
        .photo-container .photo-wrapper .photo {
            transform: scale(1.05);
        }

        &:nth-child(3n+1) .photo-container::before {
            transform: rotate(80deg);
            top: 40px;
            left: -35px;
        }
        &:nth-child(3n+1) .photo-container::after {
            transform: rotate(-25deg);
        }
        &:nth-child(3n+2) .photo-container::before {
            transform: rotate(20deg);
        }
        &:nth-child(3n+2) .photo-container::after {
            transform: rotate(-50deg);
            top: 140px;
            right: 0px;
        }
        &:nth-child(3n+3) .photo-container::before {
            transform: rotate(-25deg);
        }
        &:nth-child(3n+3) .photo-container::after {
            transform: rotate(32deg);
        }
    }
}
